import React from 'react';

function SFEIR() {
    return <><h1>SFEIR</h1>
    déso le bot marchait pas :( on voulait faire un bot thanos

        Le but était de faire une commande pour chaque pierre d’infinité de Thanos : <br /> <br />

        - La pierre de l’Espace devait rediriger tous les users étant sur la page SFEIR vers un un rick roll <br />
        - La pierre de l’Esprit devait lancer le jeu akinator avec le bot Thanos <br />
        - La pierre de la Réalité devait transformer la page de description de SFEIR en la page de description de Capgemini <br />
        - La pierre de Pouvoir devait faire un bruit d’amorçage de bombe puis faire apparaitre une explosion <br />
        - La pierre du Temps devait transformer le background de la page SFEIR en mode papyrus et changer la font en hiéroglyphes
        - La pierre de l’Amongus (Âme) étant la seule pierre dont on ne connait pas vraiment le pouvoir dans les films Marvel est la pierre sus ainsi la commande devait déclencher l’emergency meeting d’amongus et modifier tous les A en personnage d’amongus <br />
        <br /><br />l’easter egg avait pour but de déclencher le snap de thanos et de faire disparaitre la moitié des éléments de la page SFEIR aléatoirement


    </>;
}

export default SFEIR;